<template>
  <div class="treeBG" >
    <div class="panel" ref="panel" style="transform:scale(1)">
      <div class="panelTop">
        <div class="title">{{infoName}}</div>
        
        <div>
          
          <div style="display:flex;">
            <div style="display:flex;" v-if="schedule">
              <div style="display:flex;">
                Light State
                <div  :class="'box '+lightState"></div>
              </div>
             
            </div>
            
            <div style="margin-left:10px;display:flex;flex-grow:1;">  Current Time: {{ now}}  </div>
            <div style="display:flex;">Data
              <div v-if="commandState" class="box orange"></div>
              <div v-else class="box green"></div>
            </div>
          </div>
         
          <div >
          
          <div class="subpanel" style="display:flex;">
            <div v-if="schedule">
              <RadioButtons  :radioGroup="scheduleBtns"></RadioButtons>
            </div>
          <div>
            
          <div v-if="schedule" style="display:flex;margin-top:46px;margin-left:20px;">
          <div @click="getStartTime">On:{{ schedule.LightsON }} - </div>
          <div @click="getEndTime" >- Off:{{ schedule.LightsOFF }} </div> 
          </div>
        </div>
        </div>
          </div>
          <Panel v-for="(p,pi) in panels" :panel="p" :key="'p'+pi" :ref="p.Name"></Panel>
        </div>
       

        <div  id="footer" class="subpanel" style="display:flex;">
         <div class="logoB"></div> 
        
          <div class="contact">
            <div><a href="mailto:admin@christmasdisplaysolutions.com.au">admin@christmasdisplaysolutions.com.au</a></div>
            <div><a href="tel:+61 430 352 111">mobile +61 430 352 111</a></div>
            <div><a href="tel:+61 1300 88 60 44">phone  +61 1300 88 60 44</a></div>
            <div><a href="Http://www.christmasdisplaysolutions.com.au">www.christmasdisplaysolutions.com.au</a></div>
        </div>
     
        </div>  
        <div v-if="pageWidth <= 900">
          <div v-if="debugMode">Page less than 900</div>  
      </div>
      <div v-else>
        <div v-if="debugMode">page greater than 900</div>
      </div>
    </div> 
    
 <div class="panelBase">
  
 </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Device from '@/mixins/Device.js'
export default {
  name: 'Home',
  components: {
   
  },
  mixins:[Device],
  data() {
      return {
      
      }
  },
  mounted(){
  
  
  },
  computed:{
    infoName(){
      if(this.info){
        return this.info.Title
      }else{
        return "SMITHTON TREE CIRCULAR HEAD"
      }
    },
  },
  methods:{
    setDevice(){
      this.$store.dispatch("setDevice","Device1")
    }
   
  },
  watch:{
   
  }
  
}


</script>
<style lang="scss" >
@import "@/css/colors.scss";
@import "@/css/interface.scss"; 
.treeBG{
  background-image: url("../assets/images/BG2.png");
  background-size:cover;
  background-position: 50% 50%;
  
}
.panel{
  background-color: transparent;
  
  font-weight:550;
}
.subpanel{
  border:none;
  background-color:#5f5f5f75;
}
.logoB{
  margin:2vw;
  width:35VW;
  height:18vw;
  background-size:contain;
  background-repeat: no-repeat;
  background-image: url("../assets/images/ChristmasLogo.png");

}
.contact{
  margin: 2vw;
    font-size: 2vw;
    color: #b5022b;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    cursor:pointer;
  
}
.on{
    background-color:#09f809!important;
  }
  .off{
    background-color:red!important;
  }
.green{
    background-color:#09f809!important;
  }
  .orange{
    background-color:orange!important;
  }
  .inactive{
    background-color:#504343!important;
  }
  .box.true{
    background-color: #09f809!important;
  }
  .box.false{
    background-color: #504343!important;
  }
  .box.off{
    background-color: #e32525!important;
  }
</style>
